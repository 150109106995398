
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import { app } from '@config';
import ExternalSvg from '@svg/External.vue';
import PhoneSvg from '@svg/Phone.vue';
import EmailSvg from '@svg/Email.vue';
import axios from 'axios';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    data() {
        return {
            pageData: {}
        };
    },

    components: {
        ExternalSvg,
        PhoneSvg,
        EmailSvg
    },
    mounted() {
        const instance = axios.create({
            baseURL: app.api.api,
            timeout: 2500,
            headers: {}
        });
        instance.get(`/page/${this.$route.meta.id}`).then(response => {
            this.pageData = response.data;
            setTitle(response);
            return response.data;
        })
        .catch(error => {
            console.log(`🔥${error}🔥`);
            handle429(this, error);
        });
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Tickets extends Vue {}
